import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Bodysection.css'; // Import your CSS file for styling

const BodySection = () => {
  const navigate = useNavigate();
  const [chatId, setChatId] = useState('');
  const [privateChatId, setPrivateChatId] = useState('');

  const handleNextButtonClick = () => {
    if (chatId.trim() === '') {
      alert('Please enter a valid chat ID.');
      return;
    }
    // Redirect to public page
    navigate('/public', { state: { chatId } });

  };

  const handlePrivateNextButtonClick = () => {
    if (privateChatId.trim() === '') {
      alert('Please enter a valid private space ID.'); // Alert if private chat ID is empty
      return;
    }
    navigate(`/private/${privateChatId}`); // Redirect to private page with private chat ID
    // // Validate the private space ID here (e.g., check if it's empty or valid)
    // if (spaceId.trim() === '') {
    //   alert('Please enter a valid private space ID.');
    //   return;
    // }

    // // Navigate to the private page with the entered ID
    // navigate(`/private/${spaceId}`);
    //<input type="text" placeholder="Enter ID" value={spaceId} onChange={(e) => setSpaceId(e.target.value)} />
    //        <button className='private-next-btn' onClick={handlePrivateNextButtonClick}><img src="next.png" alt="next" /></button>
    //navigate('/private');
  };
  
  return (
    <>
    <div className="body-section">

      <div className='heading'>
        <h1 className='h-1'>Welcome to Share</h1><h1 className='h-2'>Bytes</h1>
      </div>

      <div className='infotxt'>
      <p>ShareBytes is the ultimate platform for effortless file sharing and collaboration. Upload, organize, and sharecontent </p>
      <p>seamlessly with full control over privacy and access. With  ShareBytes simplifiescontent management, </p>
          <p>enabling smooth and intuitive collaboration for all your needs.</p>
      </div>

      <h2 className='open'>Open your</h2>

      <div className='input-box'>

        <div className="input-container">
          <div className='lable-name'>
            <label>Public Space</label>
          </div>
          <div className='field'>
            <input type="text" placeholder="Enter ID" 
              value={chatId} onChange={(e) => setChatId(e.target.value)}/>  // Update chatId on change
            <button className='public-next-btn' onClick={handleNextButtonClick}><img src="next.png" alt="next" /></button>
          </div>
        </div>

        <div className="input-container">
          <div className='lable-name'>
            <label>Private Space</label>  
          </div>
          <div className='field-box'>
            <input type="text" placeholder="Enter ID" 
                value={privateChatId} 
                onChange={(e) => setPrivateChatId(e.target.value)}  /> // Update privateChatId on change
            <button className='private-next-btn' onClick={handlePrivateNextButtonClick}><img src="next.png" alt="next" /></button>
          </div>
        </div>

      </div>
    </div>
    
    <div className='second-white'>
      <div className='img-section'>
        <img src="" alt="img" />
      </div>
      <div className='text-content'>
        <h2 id='h1-1'>The  Collaboration Challenge</h2>
        <p id='c1-1'>Digital tools are often disconnected and hard to use, making it difficult for users to manage files and work together smoothly. This confusion leads to inefficiencies and frustration, disrupting effective teamwork.</p>
      </div>
    </div>

    <div className='third-dark'>
      <h2 className='third-lable'>The Solution: Our digital world needs a new approach</h2>
      <div className='third-content'>
        <div className='third-img-section'>
          <img src="" alt="" />
        </div>
        <div className='third-text-section'>
          <p id='solution-text1'>We address the challenge by offering a seamless file sharing and management experience tailored to modern needs. Our platform integrates advanced features with an intuitive design, bridging the gap between complex digital interactions and user-friendly solutions. By leveraging cutting-edge technology and user feedback, we ensure efficient file organization, sharing, and collaboration.</p>
          <h1 className='solution-text2'>MAKING </h1>
          <h1 className='solution-text2'>COLLABORATION</h1>
          <h1 className='solution-text2'>SIMPLE AND EFFICIENT</h1>
          <p id='solution-text1'>This platform is crucial for enhancing digital collaboration, streamlining file sharing and management, and making teamwork more efficient and effective.</p>
        </div>
      </div>
    </div>

    <div className='four-white'>
      <div>
        <h1 className='four-lable'>Boost Your Workflow with Our Platform</h1>
      </div>
      <div className='four-content'>
        <div className='four-text-section'>
          <p id='text-4'>Integrate our user-friendly file management and collaboration tools with your existing systems. Our platform seamlessly combines with your current tools to streamline file sharing, content organization, and team collaboration. With a unified interface and intuitive features, you'll achieve smoother workflows and more effective teamwork.</p>
        </div>
        <div className='four-btn-section'>
          <button className='four-btn'>Explore Our Website</button>
        </div>
      </div>
    </div>

    <div className='five-dark'>
      <div className='five-text-content'>
      <p className='five-text1'>LET'S TRY AND  DISCOVER</p>
      <p className='five-text2'>SEAMLESS</p>
      <p className='five-text2'>CONTENT SHARING</p>
      </div>
    </div>

    <div className='six-white'>
      <div className='six-top'>
          <div className='six-left'>
            <p id='six-text-1'>Seamless content sharing and intuitive file management for a connected digital experience.</p>
            <p id='six-text-2'>Get the latest skills-matching intelligence delivered to your inbox.</p>
            <div className='six-left-field'>
              <input className='six-input' type="text" placeholder='Enter your Email' />
              <button className='six-btn'>Subscribe</button>
            </div>
            <p id='six-text-3'>By subscribing you agree to with our Privacy Policy and provide consent to receive updates from our company.</p>
          </div>
          <div className='six-right'>
            <div className="six-right-text">
              <h3 className='six-right-heading'>Featurs</h3>
              <p className='six-right-content'>Content Sharing</p>
              <p className='six-right-content'>File Management</p>
              <p className='six-right-content'>Gmail Sharing</p>
            </div>
            <div className="six-right-text">
              <h3 className='six-right-heading'>Company</h3>
              <p className='six-right-content'>About us</p>
              <p className='six-right-content'>Contact</p>
              <p className='six-right-content'>Help Center</p>
            </div>
            <div className="six-right-text">
              <h3 className='six-right-heading'>Social</h3>
              <p className='six-right-content'>Instagram</p>
              <p className='six-right-content'>Twitter</p>
              <p className='six-right-content'>LinkedIn</p>
            </div>
          </div>
      </div>
      <div className='six-bottom'>
        <h1 className='six-bottom-left'>Share</h1>
        <h1 className='six-bottom-right'>Bytes</h1>
      </div>


    </div>

    </>
  );
};

export default BodySection;
