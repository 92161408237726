import React from 'react'
import './Homepage.css'
import Hader from '../Components/Home/Hader'
import Footer from '../Components/Home/Footer'
import Bodysection from '../Components/Home/Bodysection'


const Homepage = () => {
  return (
    <>
    <div className="homepage">
      <Hader />
      <Bodysection/>
      <Footer />
    </div>
    </>
    
  )
}

export default Homepage
