import React from 'react'
import './Public.css'
import Header from '../Components/Home/Hader'
import Notesection from '../Components/Public/Notesection'

const Public = () => {
  return (
    <>
    <div className='main-div'>
      <Header/>
      <Notesection/>
    </div>
    </>
  )
}

export default Public
