import React from 'react'
import "./Usermanage.css"

const Usermanage = () => {

  return (
    <>
    <div className="table-container">
  <h2>Chat</h2>
  <table>
    <thead>
      <tr>
        <th>Chat ID</th>
        <th>Chat Count</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {/* {chatData.map((chat, index) => (
        <tr key={index}>
          <td>{chat.chatId}</td>
          <td>{chat.chatCount}</td>
          <td>
            <button className="see-btn" data-chat-id={chat.chatId}>See</button>
            <button className="delete-btn" data-chat-id={chat.chatId}>Delete</button>
          </td>
        </tr>
      ))} */}
    </tbody>
  </table>
</div>
    </>
  )
}

export default Usermanage
