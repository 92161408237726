import React from 'react'
import './Buttonsection.css'

const Buttonsection = () => {
  return (
    <>
    <div className='button-container'>
    <button className="share-button"><img src="share.svg" alt="share" /></button>
    <button className="upload-button"><img src="upload.svg" alt="upload" /></button>
    </div>
    </>
  )
}

export default Buttonsection
