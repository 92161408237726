import React, { useState } from 'react';
import './Hader.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  

  return (
    <header className="header">
      <div className="logo-container">   
        <img src="logo1.svg" alt="ShareBytes Logo" />
      </div>
      <nav className="navbar" style={{ display: isMenuOpen ? 'flex' : 'none' }}>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/features">Features</a></li>
          <li><a href="/about">About</a></li>
        </ul>
      </nav>
      <div className="hamburger-menu" onClick={handleMenuClick}>
        <img src="/menu.png" alt="" />
      </div>
    </header>
  );
};

export default Header;
