import React from 'react'
import './Notesection.css'
import Buttonsection from './Buttonsection'

const Notesection = () => {
  return (
    <>
        <div class="second-page">
                <main class="text-editor-container">
                  <textarea class="text-editor"></textarea>
                </main>
                <div className="button-container">
                    <Buttonsection />
                </div>
        </div>
    </>
  )
}

export default Notesection

