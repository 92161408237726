import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from "./Frontend/Pages/Homepage";
import './App.css'
import Public from './Frontend/Pages/Public';
import Private from './Frontend/Pages/Private.jsx';
import Usermanage from './Frontend/Admin/Components/Usermanage.jsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/public" element={<Public/>} />
        <Route path="/private" element={<Private/>} />
        <Route path="/user" element={<Usermanage/>}/>
      </Routes>
    </Router>
  );
}

export default App;
