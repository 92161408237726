import React from 'react';
import './Private.css';
import Header from '../Components/Home/Hader'; // Correct import
import Notesection from '../Components/Public/Notesection';
import Buttonsection from '../Components/Public/Buttonsection';
import Filemenu from '../Components/Private/Filemenu';

const Private = () => {
  return (
    <>
      <div className='main-div'>
        <Header />
        <div className='container'>
        <Filemenu/>
        <Notesection/>
        </div>
      </div>
    </>
    
  );
};

export default Private;

