import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <>
    <footer class="footer">
      <p className='footer-text'>&copy; 2024 ShareBytes. All rights reserved.</p>
      <div class="footer-links">
        <a href="www.google.com"><u>Terms of service</u></a>
        <a href="www.google.com"><u>Privacy policy</u></a>
        <a href="www.google.com"><u>Cookies settings</u></a>
      </div>
    </footer>
    </>
  )
}

export default Footer
