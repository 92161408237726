import React, { useState } from 'react';
import './Filemenu.css';

const Filemenu = () => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]); // State for files outside folders

  // Function to create a new folder
  const handleCreateFolder = () => {
    const newFolderName = prompt('Enter folder name');
    if (newFolderName) {
      setFolders([...folders, { name: newFolderName, files: [] }]);
    }
  };

  // Function to create a new file inside a specific folder
  const handleCreateFileInFolder = (folderIndex) => {
    const newFileName = prompt('Enter file name');
    if (newFileName) {
      const updatedFolders = [...folders];
      updatedFolders[folderIndex].files.push(newFileName);
      setFolders(updatedFolders);
    }
  };

  // Function to create a new file outside folders (in general space)
  const handleCreateGeneralFile = () => {
    const newFileName = prompt('Enter file name');
    if (newFileName) {
      setFiles([...files, newFileName]);
    }
  };

  return (
    <div className="file-menu">
      {/* Header for creating folders and general files */}
      <div className="file-menu-header">
        <button className="create-folder-btn" onClick={handleCreateFolder}>+ Folder</button>
        <button className="create-file-btn" onClick={handleCreateGeneralFile}>+ File</button>
      </div>

      {/* List of general files (outside of folders) */}
      {files.length > 0 && (
        <ul className="file-list-general">
          <h4>General Files:</h4>
          {files.map((file, index) => (
            <li key={index} className="file-item"><img src="fileshare.svg" alt="file" />{file}</li>
          ))}
        </ul>
      )}

      {/* List of folders and their files */}
      <ul className="file-list">
        {folders.map((folder, folderIndex) => (
          <li key={folderIndex} className="folder-item">
            <img src="folder.svg" alt="folder" />
            <span>{folder.name}</span>
            {/* Create File Button inside folder */}
            <button className="create-file-btn" onClick={() => handleCreateFileInFolder(folderIndex)}>+ File</button>
            <ul className="file-list-nested">
              {folder.files.map((file, fileIndex) => (
                <li key={fileIndex} className="file-item"><img src="fileshare.svg" alt="file" />{file}</li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Filemenu;
